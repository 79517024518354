export function trigger(el, eventType) {
  if (typeof eventType === 'string' && typeof el[eventType] === 'function') {
    el[eventType]();
  } else {
    const event =
      eventType === 'string'
        ? new Event(eventType, { bubbles: true })
        : eventType;
    el.dispatchEvent(event);
  }
}
export default trigger;
