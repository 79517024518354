let hasLoadedOnce = false;

document.addEventListener('facetwp-refresh', function () {
  const FWP = window.FWP;
  if (
    'undefined' !== typeof FWP &&
    document.getElementById('facetwpScrollTop')
  ) {
    if (!hasLoadedOnce) {
      hasLoadedOnce = true;
    } else {
      FWP.enable_scroll = true;
      FWP.please_scroll_there = '#facetwpScrollTop';
    }

    window.FWP = FWP;
  }
});
