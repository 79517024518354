/* *
 * ========================================================================
 * Vortex Solution
 * ========================================================================
 * */

// Includes the custom Modernizr build based on the configurations set in .modernizrrc
require('Modernizr'); // eslint-disable-line import/no-extraneous-dependencies
require('nodelist-foreach-polyfill');
require('element-qsa-scope');
require('./main/dropdown-menu');
require('./main/mobile-menu');
require('./main/user-is-tabbing');
require('./main/forms');
require('./main/header');
require('./main/search-window');
require('./main/facetwp-pagination-scroll');

import vtxDomReady from './common/helpers';
import { trigger } from './common/trigger';

vtxDomReady(() => {
  document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
    anchor.addEventListener('click', function (e) {
      if (
        document.querySelector('body').classList.contains('user-is-tabbing')
      ) {
        return;
      }
      e.preventDefault();
      document.querySelector(this.getAttribute('href')).scrollIntoView({
        behavior: 'smooth',
      });
      if (
        document
          .querySelector(this.getAttribute('href'))
          .classList.contains('accordion')
      ) {
        trigger(
          document.querySelector(
            this.getAttribute('href') + ' .accordion-toggle'
          ),
          'click'
        );
      }
    });
  });
  // if there's a hash in url and it correspond to an accordion, open it
  const hash = window.location.hash;

  if (hash) {
    trigger(document.querySelector(hash + ' .accordion-toggle'), 'click');
  }
});
