import TomSelect from 'tom-select/dist/js/tom-select.base.min.js';
let fields_are_initialized = false;
function change_form_label(label, input) {
  if (input.hasClass('tomselected') || input.attr('placeholder')) {
    $(label).addClass('active');
    return;
  }
  if ('' !== input.val()) {
    $(label).addClass('active');
  }

  $(label).on('click', function () {
    $(input).focus();
    if ('SELECT' === input.prop('tagName')) {
      $(input).click();
    }
  });

  $(input)
    .on('focus', function () {
      $(label).addClass('active');
    })
    .on('focusout', function () {
      var value = $.trim($(this).val());
      if (
        value.length === 0 ||
        -1 !== $(this).val().indexOf('(___) ___-____')
      ) {
        $(label).removeClass('active');
      }
    });
  if ('SELECT' === input.prop('tagName')) {
    $(input).blur(function () {
      var value = $.trim($(this).val());
      if (
        value.length === 0 ||
        -1 !== $(this).val().indexOf('(___) ___-____')
      ) {
        $(label).removeClass('active');
      }
    });
  }
}
function add_form_events() {
  $('.gfield:not(.gfield--radio)').each(function () {
    var $field = $(this);

    if ($field.hasClass('initialized')) {
      return;
    }

    var classesExcluded = [
      'ginput-wrapper--radio',
      'ginput-wrapper--checkbox',
      'ginput-wrapper--consent',
    ];

    for (var i = 0; i < classesExcluded.length; i++) {
      if ($field.hasClass(classesExcluded[i])) {
        return;
      }
    }

    var $label;
    var $input;

    $field.addClass('initialized');

    var allFields = [];

    $label = $field;
    $input = $field.find(
      '.ginput_container input , .ginput_container textarea, .ginput_container select'
    );
    if (!$input.length) {
      return;
    }
    if (1 === $input.length) {
      allFields.push([$label, $input]);
    } else {
      //it is a repeater
      $input.each(function () {
        if (!$(this).hasClass('gform_hidden')) {
          var theLabel = $(this).closest('.gfield_repeater_cell').find('label');
          if (theLabel.length) {
            allFields.push([theLabel, $(this)]);
          }
        }
      });
    }
    allFields.forEach((element) => {
      change_form_label(element[0], element[1]);
      var $itemSelect = null;
      if ($field.hasClass('gfield--select')) {
        $itemSelect = $field.find('.gfield_select');
      } else if (
        $field.hasClass('gfield--repeater') &&
        'SELECT' === element[1].prop('tagName')
      ) {
        element[1].addClass('gfield_select');
        $itemSelect = element[1];
      }
      if ($itemSelect) {
        var tomselectOption = {
          create: false,
          optionClass: 'vtx_select__option',
          itemClass: 'vtx_select__item',
          controlInput: false,
          allowEmptyOption: true,
          labelField: 'Title',
        };
        new TomSelect($itemSelect[0], tomselectOption);
      }
    });
  });
}

function change_file_upload() {
  const multiFileUploads = document.querySelectorAll(
    '.gform_fileupload_multifile'
  );
  if (0 < multiFileUploads.length) {
    multiFileUploads.forEach(function (fileUpload) {
      fileUpload.querySelector('.gform_drop_instructions').innerHTML =
        '<i class="icon icon-upload" aria-hidden="true"></i>' +
        wp.i18n._x(
          'Drag and drop file to upload or',
          'drag and drop instruction',
          'vtx'
        );

      fileUpload.querySelector('.gform_button_select_files').innerHTML =
        '<span>' +
        wp.i18n._x('Select files', 'drag and drop instruction', 'vtx') +
        '</span>';
    });
  }
}

function change_default_display_buttons_repeater($container) {
  var $allAddButtons = $container
    .children('.gfield_repeater_item')
    .children('.gfield_repeater_buttons')
    .children('.add_repeater_item');
  if ($allAddButtons.length) {
    if (1 === $allAddButtons.length) {
      $allAddButtons.css('display', 'inline-flex');
    } else {
      $allAddButtons.each(function (index) {
        if ($allAddButtons.length - 1 === index) {
          $(this).css('display', 'inline-flex');
        } else {
          $(this).css('display', 'none');
        }
      });
    }
  }
}

$(document).on('gform_post_render', function () {
  fields_are_initialized = true;
  change_file_upload();
  add_form_events();
  window.gform.addFilter('gform_repeater_item_pre_add', function ($clone) {
    //Remove the tomselect logic from HTML duplicated by Gform repeater
    $clone.find('.ts-wrapper').detach();
    $clone.html(
      $clone
        .html()
        .replace(' tomselected', '')
        .replace(' ts-hidden-accessible', '')
    );
    return $clone;
  });
  window.gform.addAction('gform_repeater_post_item_add', function ($clone) {
    //add tomselect right after added to the DOM
    var $newSelect = $clone.find('.gfield_select');
    if ($newSelect) {
      $newSelect.each(function () {
        var tomselectOption = {
          create: false,
          optionClass: 'vtx_select__option',
          itemClass: 'vtx_select__item',
          controlInput: false,
          allowEmptyOption: true,
          labelField: 'Title',
        };
        new TomSelect($(this), tomselectOption);
      });
    }
    //add the active class like others inputs
    var $inputs = $clone.find('input , textarea, select');
    $inputs.each(function () {
      if (!$(this).hasClass('gform_hidden')) {
        var theLabel = $(this).closest('.gfield_repeater_cell').find('label');
        if (theLabel.length) {
          change_form_label(theLabel, $(this));
        }
      }
    });
    //apply our function to hide the add button on all elements but the last one
    change_default_display_buttons_repeater(
      $clone.closest('.gfield_repeater_items')
    );
  });
  window.gform.addAction(
    'gform_repeater_post_item_delete',
    function ($container) {
      change_default_display_buttons_repeater(
        $container.children('.gfield_repeater_items')
      );
    }
  );
  //add span to save button and remove svg logo
  var $saveButton = $('.gform_save_link');
  $saveButton.find('svg').detach();
  var $origText = $saveButton.text().trim();
  $saveButton.text('');
  $saveButton.html('<span>' + $origText + '</span>');
});

// ON READY
window.addEventListener('load', function () {
  if (!fields_are_initialized) {
    change_file_upload();
    add_form_events();
  }
});
