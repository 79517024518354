import * as Focus from '../common/focus';
var searchWindow = document.getElementById('search-window');
var closeSearch = document.getElementById('search-popup__close-btn');

document.addEventListener('click', function (e) {
  const searchBtn = e.target.closest('.navbar__search-btn');
  if (searchBtn) {
    searchBtn.setAttribute('aria-expanded', true);

    setTimeout(function () {
      searchWindow.classList.add('popup--open');
      document.querySelector('body').classList.add('popupOpen');

      Focus.trap_focus_inside(jQuery('#search-window'));
      document.getElementById('search-input-window-ts-control').focus();
    }, 800);
  }
});
closeSearch.addEventListener('click', function () {
  const searchBtn = document.querySelector('.navbar__search-btn');
  searchBtn.setAttribute('aria-expanded', false);
  setTimeout(function () {
    searchWindow.classList.remove('popup--open');
    document.querySelector('body').classList.remove('popupOpen');
    Focus.untrap_focus_inside(jQuery('#search-window'));
    searchBtn.focus();
  }, 800);
});
